import React from 'react';
import styles from './TabletMembers.module.scss';
import {membersList} from "../../assets/content/membersList";
import { Controller, Scene } from 'react-scrollmagic';


const TabletMembers = ({menuOpened}) => {
  return (
    <div className={styles.root}>
      <Controller >
        {membersList.map((item, index) => (
          <Scene duration={64} classToggle='activeMember' key={index}>
            <div className={styles.member}>
              <div className={styles.name}>{item.firstName}.{item.lastName}</div>
              <div className='tabletMemberAbsolute'>
                <div className='role'>{item.role}</div>
                <img className='avatar' src={item.image} alt='avatar' />
              </div>
            </div>
          </Scene>
        ))}
      </Controller>
    </div>
  );
};

export default TabletMembers;
