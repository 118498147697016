import React from 'react';
import styles from './Members.module.scss';
import cs from "classnames";
import {membersList} from "../../assets/content/membersList";

const Members = ({hoveredMember, setHoveredMember, onMemberHover}) => {
  return (
    <div className={styles.text}>
      {membersList.map((item, index) => (
        <div
          key={index}
          className={cs(styles.item, { [styles.gray]: hoveredMember })}
          onMouseEnter={() => setHoveredMember(item)}
          onMouseLeave={() => setHoveredMember(false)}
          onMouseMove={(e) => onMemberHover(e)}
        >
          {item.firstName}.{item.lastName}
          <div className={styles.absoluteWrapper}>
            <div className={styles.role}>{item.role}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Members;
