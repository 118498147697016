import React from 'react';
import styles from './Footer.module.scss';
import cs from 'classnames';
import { ReactComponent as Logo } from '../../assets/img/logo_black_bg.svg';
import ScrollableAnchor from 'react-scrollable-anchor'

const Footer = () => {
  return (
    <ScrollableAnchor id={'contact'}>
      <div className={styles.root} >
        <div className={styles.wrapper}>
          <div className={styles.flexRow}>
            <div className={styles.line} />
            <div className={styles.logo}><Logo /></div>
            <div className={styles.line} />
          </div>
          <div className={styles.textRow}>
            <div className={styles.item}>
              <p className={cs(styles.text, styles.padded)}>Address</p>
              <p className={cs(styles.text, styles.underline)}>2-4 Docentilor Street</p>
              <p className={cs(styles.text, styles.underline)}>Bucharest, Romania</p>
            </div>
            <div className={styles.item}>
              <p className={cs(styles.text, styles.padded)}>Contact</p>
              <p className={styles.text}>+40 722 38 99 03</p>
              <div className={styles.linkWrapper}><a href="mailto:hello.world@digitalmob.ro" className={cs(styles.text, styles.underline)}>hello.world@digitalmob.ro</a></div>
            </div>
          </div>
          <a href="#home" className={styles.mobileLogo}><Logo /></a>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default Footer;
