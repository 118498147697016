import React from 'react';
import styles from './MobileHeader.module.scss';
import { ReactComponent as Logo } from '../../assets/img/logo_black_bg.svg';


const MobileHeader = ({menuOpened}) => {
  return (
    <div className={styles.root}>
      <h1>Digital M.O.B.</h1>
      <Logo />
    </div>
  );
};

export default MobileHeader;
