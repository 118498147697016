import universeLogo from '../../assets/img/projects/universe/logo-universe.png'
import universe1 from '../../assets/img/projects/universe/universe1.png'
import universe2 from '../../assets/img/projects/universe/universe2.png'
import universe3 from '../../assets/img/projects/universe/universe3.png'
import universe4 from '../../assets/img/projects/universe/universe4.png'
import barnbridgeLogo from '../../assets/img/projects/barnbridge/logo-bb.png'
import barnbridge1 from '../../assets/img/projects/barnbridge/bb1.png'
import barnbridge2 from '../../assets/img/projects/barnbridge/bb2.png'
import barnbridge3 from '../../assets/img/projects/barnbridge/bb3.png'
import breakerLogo from '../../assets/img/projects/breaker/logo-breaker.png'
import breaker1 from '../../assets/img/projects/breaker/breaker1.png'
import breaker2 from '../../assets/img/projects/breaker/breaker2.png'
import breaker3 from '../../assets/img/projects/breaker/breaker3.png'
import breaker4 from '../../assets/img/projects/breaker/breaker4.png'
import tokitLogo from '../../assets/img/projects/tokit/logo-tokit.png'
import tokit1 from '../../assets/img/projects/tokit/tokit1.png'
import tokit2 from '../../assets/img/projects/tokit/tokit2.png'
import tokit3 from '../../assets/img/projects/tokit/tokit3.png'
import tokit4 from '../../assets/img/projects/tokit/tokit4.png'
import alethioLogo from '../../assets/img/projects/alethio/logo-alethio.png'
import alethio1 from '../../assets/img/projects/alethio/alethio1.png'
import alethio2 from '../../assets/img/projects/alethio/alethio2.png'
import alethio3 from '../../assets/img/projects/alethio/alethio3.png'
import alethio4 from '../../assets/img/projects/alethio/alethio4.png'
import spaceraceLogo from '../../assets/img/projects/spacerace/logo-spacerace.png'
import spacerace1 from '../../assets/img/projects/spacerace/spacerace1.png'
import spacerace2 from '../../assets/img/projects/spacerace/spacerace2.png'
import spacerace3 from '../../assets/img/projects/spacerace/spacerace3.png'
import filstats1 from '../../assets/img/projects/filstats/filstats01.png'
import filstats2 from '../../assets/img/projects/filstats/filstats02.png'
import filstats3 from '../../assets/img/projects/filstats/filstats03.png'
import filpoll1 from '../../assets/img/projects/filpoll/filpoll01.png'
import filpoll2 from '../../assets/img/projects/filpoll/filpoll02.png'
import filpoll3 from '../../assets/img/projects/filpoll/filpoll03.png'
import near1 from '../../assets/img/projects/near/near01.png'
import near2 from '../../assets/img/projects/near/near02.png'
import near3 from '../../assets/img/projects/near/near03.png'
import allinfraLogo from '../../assets/img/projects/allinfra/logo-allinfra.png'
import allinfra1 from '../../assets/img/projects/allinfra/allinfra1.png'
import allinfra2 from '../../assets/img/projects/allinfra/allinfra2.png'
import allinfra3 from '../../assets/img/projects/allinfra/allinfra3.png'
import allinfra4 from '../../assets/img/projects/allinfra/allinfra4.png'
import kaikoLogo from '../../assets/img/projects/kaiko/logo-kaiko.png'
import kaiko1 from '../../assets/img/projects/kaiko/kaiko1.png'
import kaiko2 from '../../assets/img/projects/kaiko/kaiko2.png'
import kaiko3 from '../../assets/img/projects/kaiko/kaiko3.png'
import singulardtvLogo from '../../assets/img/projects/singulardtv/logo-singular.png'
import singulardtv1 from '../../assets/img/projects/singulardtv/singular1.png'
import singulardtv2 from '../../assets/img/projects/singulardtv/singular2.png'
import singulardtv3 from '../../assets/img/projects/singulardtv/singular3.png'
import gnosisLogo from '../../assets/img/projects/gnosis/logo-gnosis.png'
import gnosis1 from '../../assets/img/projects/gnosis/gnosis1.png'
import gnosis2 from '../../assets/img/projects/gnosis/gnosis2.png'
import gnosis3 from '../../assets/img/projects/gnosis/gnosis3.png'
import atparLogo from '../../assets/img/projects/atpar/logo-atpar.png'
import atpar1 from '../../assets/img/projects/atpar/atpar1.png'
import atpar2 from '../../assets/img/projects/atpar/atpar2.png'
import atpar3 from '../../assets/img/projects/atpar/atpar3.png'
import codefiLogo from '../../assets/img/projects/codeficompliance/logo-codefi.png'
import codefi1 from '../../assets/img/projects/codeficompliance/codefi1.png'
import codefi2 from '../../assets/img/projects/codeficompliance/codefi2.png'
import codefi3 from '../../assets/img/projects/codeficompliance/codefi3.png'
import codefidata1 from '../../assets/img/projects/codefidata/codefidata01.png'
import codefidata2 from '../../assets/img/projects/codefidata/codefidata02.png'
import codefidata3 from '../../assets/img/projects/codefidata/codefidata03.png'
import filgreenLogo from '../../assets/img/projects/filecoingreen/logo-green.png'
import filgreen1 from '../../assets/img/projects/filecoingreen/filgreen1.png'
import filgreen2 from '../../assets/img/projects/filecoingreen/filgreen2.png'
import filgreen3 from '../../assets/img/projects/filecoingreen/filgreen3.png'
import filgreen4 from '../../assets/img/projects/filecoingreen/filgreen4.png'
import metamaskLogo from '../../assets/img/projects/metamask/logo-metamask.png'
import metamask1 from '../../assets/img/projects/metamask/metamask1.png'
import metamask2 from '../../assets/img/projects/metamask/metamask2.png'
import metamask3 from '../../assets/img/projects/metamask/metamask3.png'
import makerdaoLogo from '../../assets/img/projects/makerdao/logo-maker.png'
import makerdao1 from '../../assets/img/projects/makerdao/maker1.png'
import makerdao2 from '../../assets/img/projects/makerdao/maker2.png'
import makerdao3 from '../../assets/img/projects/makerdao/maker3.png'
import filrep1 from '../../assets/img/projects/filrep/filrep01.png'
import filrep2 from '../../assets/img/projects/filrep/filrep02.png'
import filrep3 from '../../assets/img/projects/filrep/filrep03.png'



export const productsList = [
  {
    name: 'Barnbridge',
    urlParam: 'barnbridge',
    thumbnail: barnbridgeLogo,
  },
  {
    name: 'Universe',
    urlParam: 'universe',
    thumbnail: universeLogo,
  },
  {
    name: 'SingularDTV',
    urlParam: 'singulardtv',
    thumbnail: singulardtvLogo,
  },
  {
    name: 'Gnosis',
    urlParam: 'gnosis',
    thumbnail: gnosisLogo,
  },
  {
    name: 'Breaker',
    urlParam: 'breaker',
    thumbnail: breakerLogo,
  },
  {
    name: 'Tokit',
    urlParam: 'tokit',
    thumbnail: tokitLogo,
  },
  {
    name: 'Alethio',
    urlParam: 'alethio',
    thumbnail: alethioLogo,
  },
  {
    name: 'Metamask',
    urlParam: 'metamask',
    thumbnail: metamaskLogo,
  },
  {
    name: 'CODEFI',
    urlParam: 'codefi',
    thumbnail: codefiLogo,
    multiplier: 2,
  },
  {
    name: 'Filecoin',
    urlParam: 'filecoin',
    thumbnail: spaceraceLogo,
    multiplier: '5',
  },
  {
    name: 'MakerDAO',
    urlParam: 'makerdao',
    thumbnail: makerdaoLogo,
  },
  {
    name: 'Allinfra',
    urlParam: 'allinfra',
    thumbnail: allinfraLogo,
  },
  {
    name: 'ATPAR',
    urlParam: 'atpar',
    thumbnail: atparLogo,
  },
  {
    name: 'KAIKO',
    urlParam: 'kaiko',
    thumbnail: kaikoLogo,
  },
]

export const productsDetails = {
  barnbridge: {
    images: [barnbridge1, barnbridge2, barnbridge3],
    name: 'Barnbridge',
    title: null,
    description: [
      'BarnBridge is the first tokenised risk protocol. Before the advent of smart contract technology it was close to impossible to track & attribute yield to a divided allotment of capital, trustless & transparent, to provide hedges against any and all fluctuations. Conceptually, you can build derivative products from any type of market driven fluctuation to hedge various risks.',
      'Examples include, but are not limited to, interest rate sensitivity, fluctuations in underlying market price, fluctuations in predictive market odds, fluctuations in default rates across mortgages, fluctuations in commodity prices, and a seemingly infinite number of market based fluctuations to hedge a particular position.',
      'SMART Yield allows users to mitigate the variable yield volatility of other projects, such as Aave or Compound, by introducing senior and junior tranche derivatives reaching over $200M in total value locked at its highest point.',
      'SMART Alpha pools are two-sided, with users taking either a senior or junior position on a specific ERC-20-compatible asset (e.g., ETH, wBTC, UNI) and operated with pools represented by ERC-20 proofs of liquidity (e.g., senETH, junETH).',
      'The open source Yield Farming and DAO contracts built for BarnBridge have now been used for more than 5 projects.',
      'The yield farming staking contract has had over $3B in total deposits over the past year and held over $600m at the highest point during the BarnBridge Yield Farming phase, making it the top holder of USDC on Ethereum and top 3 DAI holders at that moment.',
    ],
    details: [
      {
        label: 'Client',
        values: ['BARNBRIDGE']
      },
      {
        label: 'Execution time',
        values: ['20 months']
      },
      {
        label: 'Services Rendered',
        values: ['Concept / White Paper', 'Backend Dev', 'Frontend Dev', 'DevOps', 'Design', 'UX/UI']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker', 'TypeScript', 'React', 'Figma']
      },
    ],
    urls: [
      {
        url: "https://barnbridge.com/",
        urlText: "barnbridge.com",
      }
    ],
    prevProject: 'kaiko',
    nextProject: 'universe',

  },
  universe: {
    images: [universe1, universe2, universe3, universe4],
    name: 'Universe',
    title: null,
    description: [
      'A platform and a set of tools that enables mintig single or multiple NFTs, creating and editing NFT Collections, and running auctions with multiple NFTs per winner. We delivered infrastructure, backend solutions and code for minting and auctioning while overseeing the whole project.'
    ],
    details: [
      {
        label: 'Client',
        values: ['UNIVERSE.XYZ']
      },
      {
        label: 'Execution time',
        values: ['12 months']
      },
      {
        label: 'Services Rendered',
        values: ['Backend Dev', 'DevOps', 'Product Management']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker']
      },
    ],
    urls: [
      {
        url: "https://www.universe.xyz/",
        urlText: "universe.xyz",
      }
    ],
    prevProject: 'barnbridge',
    nextProject: 'singulardtv',

  },
  singularDtv: {
    images: [singulardtv1, singulardtv2, singulardtv3],
    name: 'Singular',
    title: 'DTV',
    description: [
      'SingularDTV is a blockchain entertainment studio laying the foundation for a decentralized entertainment industry. Building the future of rights management, project funding, and peer-to-peer distribution, SingularDTV’s platform empowers artists and creators with powerful tools to manage projects from development to distribution.\n' +
      'We were the architects and builders that created all the tools and products from the ground up starting with the ICO which was the largest and quickest at the time, raising 580.000 ETH (ETH was 13 USD that day) in 15 minutes, despite the fact that it was right after the DAO fiasco.',
      'Tokit SingularDTV\'s Rights Management and Product Creation Application',
      'Codex A research and design lab dedicated to the development and proliferation of tokenized ecosystems.',
      'Decentralized Exchange The first exchange for artists and creators where you can buy and sell the tokens generated in Tokit',
      'LaunchPad A project funding launch pad for tokenized ecosystems created in Tokit.',
      'Legal Repository A legal repository of smart contracts where you can add terms, conditions, and more legal specifics to your project created in Tokit. ',
      'Production Services/Crew A peer to peer crew and production services hiring application. Connect with entertainment production professionals in the city you\'re creating your project.',
      'Equipment rental A peer to peer equipment rental application. Locate and rent the production equipment your project needs.',
      'Talent Management Manages offers to above-the-line talent, processes their payments, and organizes terms/conditions of their deals via smart contracts.',
      'Digital marketing Offers a menu-style list of digital marketing packages and territories to spread your project around the world.',
      'Union Interface For blockchain signatory productions needing an efficient way to adhere to union rules and administer union processes and pay scales (launching 2019)',
      'EtherVision SingularDTV\'s decentralized peer to peer distribution portal',
    ],
    details: [
      {
        label: 'Client',
        values: ['SingularDTV']
      },
      {
        label: 'Fact',
        values: ['580K ETH raised at ICO']
      },
      {
        label: 'Services Rendered',
        values: ['Concept / White Paper', 'Backend Dev', 'DevOps', 'Frontend Dev', 'UX/UI']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker', 'JavaScript', 'React', 'Figma']
      },
    ],
    urls: null,
    prevProject: 'universe',
    nextProject: 'gnosis',
  },
  gnosis: {
    images: [gnosis1, gnosis2, gnosis3],
    name: 'Gnosis',
    title: null,
    description: [
      'Gnosis builds new market mechanisms to make a redistributed future possible. Through blockchain-based, decentralized platforms, they enable the redistribution of resources—from  assets to incentives, and information to ideas.',
      'Gnosis is a company that was founded to build a decentralized prediction market platform. Over the years it has built many other tools and applications to help the Ethereum ecosystem. We were part of the Gnosis team in their very early days, and delivered their first applications.',
      'Biggest contribution was building GNOSIS reverse dutch auction token launch app, first in the ethereum ecosystem, that raised 250.000 ETH.'
    ],
    details: [
      {
        label: 'Client',
        values: ['GNOSIS']
      },
      {
        label: 'Fact',
        values: [' First Reverse Dutch Auction On Ethereum']
      },
      {
        label: 'Services Rendered',
        values: ['Gnosis Launch App']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'UX/UI/Frontend', 'Design']
      },
    ],
    urls: null,
    prevProject: 'singulardtv',
    nextProject: 'breaker',
  },
  breaker: {
    images: [breaker1, breaker2, breaker3, breaker4],
    name: 'Breaker',
    title: null,
    description: [
      'Breaker founded by SingularDTV is a blockchain solution-based for entertainment right and royalties management that enables intellectual property right holders to gain increased visibility into tracking, management and payment processing. Breaker Studios is a production, financing and distribution company championing director driven films from around the world.'
    ],
    details: [
      {
        label: 'Client',
        values: ['BREAKER']
      },
      {
        label: 'Services Rendered',
        values: ['Concept / White Paper', 'System Architecture', 'Backend Dev', 'DevOps', 'Frontend Dev', 'UX/UI']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker', 'React', 'Figma']
      },
    ],
    urls: null,
    prevProject: 'gnosis',
    nextProject: 'tokit',
  },
  tokit: {
    images: [tokit1, tokit2, tokit3, tokit4],
    name: 'Tokit',
    title: null,
    description: [
      'In the centralized legacy world, business structures are corporations and LLCs. In this new world of Ethereum and Breaker, the new type of business structure is a tokenized ecosystem. Tokit empowers you to create your next movie, music album, podcast, ebook - any creative project - using the tools, community and freedom of Breaker’s decentralized entertainment economy. Buy, sell, import and transfer your tokens - all from a single, unified interface. Watch in realtime the evolution of your intellectual property from monitoring and managing the funds fuelling your projects to immediate access to your projects’ revenue and rewards.'
    ],
    details: [
      {
        label: 'Client',
        values: ['SINGULAR DTV']
      },
      {
        label: 'Services Rendered',
        values: ['System Architecture', 'Backend Dev', 'DevOps', 'Frontend Dev', 'UX/UI']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker', 'React', 'Figma']
      },
    ],
    urls: [
      {
        url: "https://tokit.io/",
        urlText: "tokit.io",
      }
    ],
    prevProject: 'breaker',
    nextProject: 'alethio',
  },
  alethio: {
    images: [alethio1, alethio2, alethio3, alethio4],
    name: 'Alethio',
    title: null,
    description: [
      'Alethio is a blockchain data analytics and visualization platform that captures and synthesizes blockchain data in real time, enriches and processes it - offering an integrated, deployable analytics solution for private and public blockchains.',
      'Blockchain analytics platforms are often geared towards developers and leave average users in the dark. At the same time, most users still don’t have the resources they need to really make sense of the blockchain — whether that’s accessing real time block data, analyzing the application layer, detecting anomalies, or monitoring the statistical signals that translate to larger KPIs.',
      'Alethio’s SaaS platform is fully deployable on private Ethereum based networks, offering:',
      '● An advanced block explorer',
      '● A monitoring tool that allows users to setup multi channel alerts of anomalies and/or other events critical to their business function - to receive real time notifications as they happen',
      '● A reporting tool - that users can take advantage of to get historical, on-demand large datasets based on custom queries',
      '● A subscription-based API offering providing access to real-time, consistently enriched Ethereum data',
      '● Open Source Deployable Analytics tools - blockchain network monitor, lite block explorer fully customizable and use-case focused data pipeline; Critical, infrastructure-level analytics, research & visualization capabilities.'
    ],
    details: [
      {
        label: 'Client',
        values: ['ALETHIO']
      },
      {
        label: 'Services Rendered',
        values: ['Concept', 'Product Design', 'Project Management', 'Backend Dev', 'DevOps', 'Frontend Dev', 'UX/UI']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'Python', 'Kafka', 'PostgreSQL', 'Apache Cassandra', 'Deepstream', 'NodeJs', 'AWS', 'Docker', 'React', 'Figma']
      },
    ],
    urls: null,
    prevProject: 'tokit',
    nextProject: 'metamask',
  },
  metamask: {
    images: [metamask1, metamask2, metamask3],
    name: 'Metamask',
    title: null,
    description: [
      'Metamask was the first user-friendly gateway to Ethereum blockchain apps, allowing users to create wallets and operate transactions with confidence and ease. We were part of the team that designed and launched the product. Metamask now has over 1 million users.'
    ],
    details: [
      {
        label: 'Client',
        values: ['CONSENSYS']
      },
      {
        label: 'Fact',
        values: ['Metamask is everywhere']
      },
      {
        label: 'Services Rendered',
        values: ['Design']
      },
    ],
    urls: [
      {
        url: "https://metamask.io/",
        urlText: "metamask.io",
      }
    ],
    prevProject: 'alethio',
    nextProject: 'codefi',
  },
  codefi: {
    images: [codefi1, codefi2, codefi3],
    name: 'CODEFI',
    title: 'COMPLIANCE, \nDATA',
    description: [
      'Codefi Compliance empowers businesses to efficiently address rigorous regulations and avoid hefty penalties by delivering advanced compliance capabilities that include know-your-transaction (KYT) frameworks, high-risk case management, and real-time reporting.',
      'Similarly to our contribution to the CODEFI Data product suite, the data core of the CODEFI Compliance solution was based on tech built by our team under the Alethio umbrella.',
      'separator',
      'A data and risk analytics platform that aggregates Decentralized Finance data in a suite of products designed to help understanding the risks of deploying capital in DeFi.',
      'We were part of the team that built the data core and pipeline powering the Inspect, Compare, and DeFi score products.'
    ],
    details: [
      {
        label: 'Client',
        values: ['CONSENSYS']
      },
      {
        label: 'Services Rendered',
        values: ['Concept', 'System Architecture', 'DevOps', 'Backend Dev']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker', 'JavaScript', 'Figma']
      },
    ],
    urls: null,
    prevProject: 'metamask',
    nextProject: 'filecoin',
  },
  filecoin: {
    images: [spacerace1, spacerace2, spacerace3, filgreen2, filgreen3, filgreen4],
    name: 'FILECOIN',
    title: 'SPACERACE, \nFILREP, \nFILGREEN,\nFILSTATS,\nFILPOLL',
    description: [
      'The testnet incentives program is a collaborative competition intended to stress-test the network, encourage participation all over the world, and help miners get ready to run the world’s biggest decentralized storage network.',
      'separator',
      'FilRep is a reputation system implementation for the Filecoin ecosystem. The main purpose of the reputation system is to help clients decide with whom to make storage and retrieval deals.',
      'separator',
      'Filecoin Green is a tool that estimates and tracks the energy consumption of filecon miners and also functions as an incentive program for miners to purchase and use green energy.',
      'separator',
      'In a decentralized p2p network, aggregating node data from across the network is very valuable to reduce reliance on any single node’s data as a source of truth.',
      'Filstats, the open source Filecoin Network Monitor allows the community to register their nodes and see the distribution of the client softwares being run (node types and versions), the way nodes sync chain heights, number of peers, number of messages pending in the mempool, storage power and more - giving an overall view of the state of the network in real time.',
      'separator',
      'FilPoll is a Filecoin community polling tool for gauging community sentiment on Filecoin Improvements Proposals.',
      'It can tally poll results based on a user\'s FIL token holdings, a miner\'s storage mining power, or the addresses of verified core developers, app developers, or ecosystem collaborator organizations, or the amount stored by storage clients. All poll results are saved to Textile Buckets on IPFS so they can be audited later on.',
    ],
    details: [
      {
        label: 'Client',
        values: ['Protocol Labs']
      },
      {
        label: 'Services Rendered',
        values: ['System Architecture', 'Backend Dev', 'DevOps', 'Frontend Dev', 'Project Management']
      },
      {
        label: 'Tech',
        values: ['NodeJs', 'AWS', 'Docker', 'JavaScript', 'React']
      },
    ],
    urls: [
      {
        url: "https://spacerace.filecoin.io/",
        urlText: "spacerace.filecoin.io",
      },
      {
        url: "https://filrep.io/",
        urlText: "filrep.io",
      },
      {
        url: "https://filecoin.energy/",
        urlText: "filecoin.energy",
      },
      {
        url: "https://filstats.io/",
        urlText: "filstats.io",
      },
      {
        url: "https://filpoll.io/",
        urlText: "filpoll.io",
      },
    ],
    prevProject: 'codefi',
    nextProject: 'makerdao',
  },
  makerDao: {
    images: [makerdao1, makerdao2, makerdao3],
    name: 'MakerDAO',
    title: null,
    description: [
      'The ability to search through data to access and display insights is predicated on building data pipelines and explorers that make it possible. The most important part of the project, the data pipeline will allow for the other modules to be built on top.',
      'Continuously monitoring risk will be a very important part of the risk management that multiple collateral types and increased DAI issuance need. Asset based metrics and pricing data need to be accurately and continuously updated.'
    ],
    details: [
      {
        label: 'Client',
        values: ['MakerDAO']
      },
      {
        label: 'Services Rendered',
        values: ['Backend Dev', 'DevOps', 'Project Management']
      },
      {
        label: 'Tech',
        values: ['Golang', 'AWS', 'Docker']
      },
    ],
    url: null,
    urlText: null,
    prevProject: 'filecoin',
    nextProject: 'allinfra',
  },
  allinfra: {
    images: [allinfra1, allinfra2, allinfra3, allinfra4],
    name: 'Allinfra',
    title: null,
    description: [
      'Allinfra is the developer of enterprise software Allinfra Climate — designed to help institutions achieve their sustainability goals. Together with its asset tokenization platform Allinfra Digital, Allinfra brings access, choice and liquidity to global infrastructure and environmental assets.'
    ],
    details: [
      {
        label: 'Client',
        values: ['ALLINFRA']
      },
      {
        label: 'Services Rendered',
        values: ['System Architecture', 'Backend Dev', 'DevOps', 'Frontend Dev', 'Project Management']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'NodeJs', 'AWS', 'Docker', 'JavaScript', 'React']
      },
    ],
    url: "https://allinfra.com/",
    urlText: "allinfra.com",
    prevProject: 'makerdao',
    nextProject: 'atpar',
  },
  atpar: {
    images: [atpar1, atpar2, atpar3],
    name: 'Atpar',
    title: null,
    description: [
      'The company behind ACTUS protocol -- whose goal is to provide the Ethereum community and the TradFi world with the tools necessary for fulfilling the vision of an open, interoperable and frictionless financial ecosystem. At its core, the ACTUS Protocol leverages the Algorithmic Contract Types Unified Standard (ACTUS) which is a formal representation of all kinds of financial contract types in terms of their financial obligations.'
    ],
    details: [
      {
        label: 'Client',
        values: ['ATPAR']
      },
      {
        label: 'Services Rendered',
        values: ['Adviser']
      },
    ],
    url: null,
    urlText: null,
    prevProject: 'allinfra',
    nextProject: 'kaiko',
  },
  kaiko: {
    images: [kaiko1, kaiko2, kaiko3],
    name: 'Kaiko',
    title: null,
    description: [
      'A pipeline capable of ingesting large amounts of DEX trade data from the blockchain - and passing it through a methodology that captures the most relevant pieces of information. The outputs should be consistent and follow a certain format. On-chain data is difficult to process due to the structure of blockchain nodes. Data consistency and integrity is another problem, and this type of endeavour is predicated on having real time data to process.',
      'For example, when dealing with trades made on crypto exchanges - whether they are centralised exchanges or decentralised exchanges - in order for the data feeds to be actionable, they need to be in real time, you need to be able to see trade data as it happens.',
      'Chain reorg handling is crucial for the final data usability - our scraper solution watches the blockchain and scrapes the raw data, making it available for further processing - while also providing the ability to either handle chain reorgs in real time or process the data with a delay in order to avoid them completely. Whether it\'s charting, trading bots, strategy testing or portfolio management and valuation - they are all driven by machine readable data.',
      'Processed data includes trades on all the major Decentralized Exchanges, as well as all of the actions performed on the major Lending Platforms.'
    ],
    details: [
      {
        label: 'Client',
        values: ['KAIKO']
      },
      {
        label: 'Services Rendered',
        values: ['Concept', 'System Architecture', 'Backend Dev', 'DevOps', 'Project Management']
      },
      {
        label: 'Tech',
        values: ['Solidity', 'Golang', 'AWS', 'Docker']
      },
    ],
    url: null,
    urlText: null,
    prevProject: 'atpar',
    nextProject: 'barnbridge',
  },
};
