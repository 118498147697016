export const newsList = [
  {
    date: 'Feb 7, 2023',
    title: 'Bumper Protocol Accelerates Development',
    url: 'https://finance.yahoo.com/news/bumper-protocol-accelerates-development-crypto-133000552.html',
  },
  {
    date: 'Apr 15, 2021',
    title: 'FilRep.io - A Filecoin Miner Reputation System v1 by Digital Mob',
    url: 'https://filecoin.io/blog/posts/filrep.io-a-filecoin-miner-reputation-system-v1-by-digital-mob/',
  },
  {
    date: 'Dec 20, 2020',
    title: 'The Team Behind BarnBridge',
    url: 'https://medium.com/barnbridge/the-team-behind-barnbridge-332f0620d083#:~:text=ParaFi%20Capital%2C%20investment%20firm,blockchain%20and%20decentralized%20finance%20markets',
  },
  {
    date: 'Dec 26, 2017',
    title: 'How Digital MOB Became the Romanian Node of the Blockchain Movement',
    url: 'https://medium.com/singulardtv/how-digital-mob-became-the-romanian-node-of-the-blockchain-movement-baa806841b13',
  },
]
