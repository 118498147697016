import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Homepage from "./pages/Homepage";
import Product from "./pages/Product";

const Routes = () => {
  return (
    <Switch>
      <Route path="/product/:productName">
        <Product />
      </Route>
      <Route path="/">
        <Homepage />
      </Route>
    </Switch>
  );
};

export default Routes;
