import React, {useState} from 'react';

import { useDencrypt } from '../../components/MyDencrypt';

const GlitchText = ({values}) => {
  const [result, setResult] = useDencrypt();
  const [className, setClassName] = useState('glitch')
  React.useEffect(() => {
    let i = 0;
    let run = true;
    let ranOnce = false // disable loop

    const loop = async () => {
      while (run && !ranOnce) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await setResult(values[i]);

        i = i === values.length - 1 ? 0 : i + 1;
        ranOnce = true;
        await setClassName('glitch breakWord');
      }
    };

    if (setResult) {
      loop();
    }

    return () => {
      run = false;
    };
  }, [setResult]);

  return (
    <span className={className}>
      {result}
    </span>
  );
};

export default GlitchText;
