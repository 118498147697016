import React from 'react';
import styles from './Article.module.scss';
import cs from "classnames";

const Article = ({date, text, externalUrl, onEnter, onLeave, hoveredArticle}) => {
  return (
    <div className={cs(styles.container, { [styles.gray]: hoveredArticle })} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <a href={externalUrl} target="_blank" rel="noreferrer">
        <p className={styles.date}>{date}</p>
        <p className={styles.text}>{text}</p>
      </a>
    </div>
  );
};

export default Article;
