import './App.css';
import { Switch, Route } from 'react-router-dom';
import Routes from './Routes';
import {useLocation} from "react-router";
import {useLayoutEffect} from "react";

const App = () => {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/" render={() => <Routes />} />
    </Switch>
  );
}

export default App;
