import React from 'react';
import styles from './SkillsList.module.scss';
import cs from "classnames";

const SkillsList = ({mobileHidden}) => {
  return (
    <div className={cs(styles.wrapper,{ [styles.mobileHidden]: mobileHidden })}>
      <div className={styles.list}>
        <div className={styles.listItem}>AWS</div>
        <div className={styles.listItem}>Docker</div>
        <div className={styles.listItem}>EVM</div>
        <div className={styles.listItem}>Solidity</div>
        <div className={styles.listItem}>Golang</div>
        <div className={styles.listItem}>Python</div>
        <div className={styles.listItem}>JavaScript</div>
        <div className={styles.listItem}>TypeScript</div>
        <div className={styles.listItem}>HCL</div>
        <div className={styles.listItem}>Node.js</div>
        <div className={styles.listItem}>Kafka</div>
        <div className={styles.listItem}>PostgreSQL</div>
        <div className={styles.listItem}>React</div>
        <div className={styles.listItem}>Objective-C</div>
        <div className={styles.listItem}>Swift</div>
        <div className={styles.listItem}>Figma</div>
        <div className={styles.listItem}>Agile</div>
      </div>
    </div>
  );
};

export default SkillsList;
