import React, {useEffect, useState} from 'react';
import styles from './ProductDetails.module.scss';


import { ReactComponent as XBlack } from '../../assets/img/xBlackBg.svg';
import { ReactComponent as ExternalWhite } from '../../assets/img/WhiteBgExternalCursor.svg';
import { NavLink } from 'react-router-dom';
import ProductCarousel from "../ProductCarousel";
import {useHistory} from "react-router";
import {useSwipeable} from "react-swipeable";

const ProductDetails = ({product}) => {
  const images = product.images;
  const history = useHistory();
  const [selectedImageIndex, setSelectedImageIndex] = useState(window.innerWidth < 769 ? 0 : 1);

  useEffect(() => {
    setSelectedImageIndex(window.innerWidth < 769 ? 0 : 1)
  }, [product]);

  const handleChangeSlide = index => {
    setSelectedImageIndex(index);
  }

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => history.push(`/product/${product.nextProject}`),
    onSwipedLeft: (eventData) => history.push(`/product/${product.prevProject}`),
  });

  return (
    <div className={styles.root}>
      <ProductCarousel images={images} handleChange={index => handleChangeSlide(index)} selectedItem={selectedImageIndex}/>
      <div className={styles.closeBtn}><NavLink to={'/#work'} style={{cursor: "inherit"}}><XBlack /></NavLink></div>
      <div className={styles.details} {...handlers}>
        <div className={styles.desktopPrev} onClick={() => history.push(`/product/${product.prevProject}`)}/>
        <div className={styles.desktopNext} onClick={() => history.push(`/product/${product.nextProject}`)}/>
        <div className={styles.scrollbar}>
          <div
            className={styles.block}
            style={
              {
                left: selectedImageIndex / images.length * 100 + '%',
                width: 100 / images.length + '%'
              }
            }
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.leftColumn}>
            <div>
              <div className={styles.projectName}>{product.name}</div>
              { product.title && <div className={styles.subtitle}>{product.title}</div>}
            </div>
            <div className={styles.description}>
              {product.description.map((item, index) => (
                item === 'separator' ? <div className={styles.separator}/> :
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className={styles.desktopUrls}>
              {product.urls && product.urls.map((item, index) => (<a className={styles.link} href={item.url} target="_blank" rel="noreferrer" key={`url${index}`}>{item.urlText}</a>))}
            </div>
          </div>
          <div className={styles.rightColumn}>

            {product.details.map((detail, index) => (
              <div className={styles.group} key={index}>
                <div className={styles.label}>{detail.label}</div>
                {detail.values.map((value, valueIndex) => (
                  <div className={styles.item} key={valueIndex}>{value}</div>
                ))}
              </div>
            ))}
            {product.urls &&
              product.urls.map((item, index) => (<a className={styles.mobileLink} href={item.url} target="_blank" rel="noreferrer" key={`mobileUrl${index}`}>
                {item.urlText}
              </a>))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
