import React, {useState} from 'react';
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as External } from '../../assets/img/BlackBgExternalCursor.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {newsList} from "../../assets/content/newsList";

const MobileNews = () => {

  const [currentArticle, setCurrentArticle] = useState(0)

  const onChangeHandler = (index, item) => {
    setCurrentArticle(index)
  }

  return (
    <div className="news-carousel-wrapper">
      <div className="newsProgressBarWrapper"><div className="newsProgressBarRect" style={{left: (currentArticle / newsList.length) * 100 + '%', width: 100 / newsList.length + '%'}}/></div>
      <Carousel
        onChange={(index, item) => onChangeHandler(index, item)}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeScrollTolerance={30}
      >
        {newsList.map((item, index) => (
          <a href={item.url} target="_blank" rel="noreferrer" className="articleWrapper" key={index}>
            <div className="articleDate">{item.date}</div>
            <div className="articleTitle">{item.title}</div>
            <div className="articleArrow"><External /></div>
          </a>
        ))}
      </Carousel>
    </div>
  );
};

export default MobileNews;
