import React from 'react';
import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../assets/img/logo_black_bg.svg';
import { ReactComponent as MenuWhite } from "../../assets/img/menuWhiteBg.svg";


const Header = ({openMenu}) => {
  return (
    <div className={styles.root}>
      <a href="#home"><Logo /></a>
      <div className={styles.menu}>
        <a href="#work"><button className={styles.item}><span>Work</span></button></a>
        <a href="#flow"><button className={styles.item}><span>Flow</span></button></a>
        <div className={styles.title}>Digital M.O.B.</div>
        <a href="#team"><button className={styles.item}><span>Team</span></button></a>
        <a href="#press"><button className={styles.item}><span>Press</span></button></a>
      </div>
      <div className={styles.languageSelector}>EN</div>
      <div className={styles.menuBtn} onClick={() => openMenu()}><MenuWhite /></div>
    </div>
  );
};

export default Header;
