import cata from '../img/Team/cata.png'
import sabau from '../img/Team/adrian-sabau.png'
import alexandr from '../img/Team/alexandr-ricov.png'
import alexmatei from '../img/Team/alexandru-matei.png'
import tudorache from '../img/Team/alexandru-tudorache.png'
import astefan from '../img/Team/andreea-stefan.png'
import andreir from '../img/Team/andrei-rykov.png'
import arkan from '../img/Team/arkan-gerges.png'
import bogdan from '../img/Team/bogdan-burcea.png'
import gheorghe from '../img/Team/bogdan-gheorghe.png'
import blacatusu from '../img/Team/bogdan-lacatusu.png'
import casian from '../img/Team/casian-lacatusu.png'
import catalinbara from '../img/Team/catalin-bara.png'
import catalindorgo from '../img/Team/catalin-dorgo.png'
// import catalin from '../img/Team/catalin-dragu.png'
import cezar from '../img/Team/cezar-paraschiv.png'
import cristi from '../img/Team/cristian-bajenaru.png'
import denis from '../img/Team/denis-firu.png'
import dragos from '../img/Team/dragos-rizescu.png'
import gstefan from '../img/Team/george-stefan.png'
import liviu from '../img/Team/liviu-damian.png'
import milad from '../img/Team/milad-mostavi.png'
import rares from '../img/Team/rares-tarabega.png'
import popra from '../img/Team/razvan-pop.png'
import slava from '../img/Team/slava-matvienco.png'
import oancius from '../img/Team/stefan-oancea.png'
import vadim from '../img/Team/vadim-lungu.png'
import pvirgiliu from '../img/Team/virgiliu-pacuretu.png'
import vladsuciu from '../img/Team/vlad-suciu.png'


export const membersList = [
  {
    firstName: 'Adrian',
    lastName: 'Sabau',
    role: 'Backend Dev',
    image: sabau,
  },
  {
    firstName: 'Alexandr',
    lastName: 'Ricov',
    role: 'Frontend Dev',
    image: alexandr,
  },
  {
    firstName: 'Alexandru',
    lastName: 'Matei',
    role: 'Full Stack Dev',
    image: alexmatei,
  },
  {
    firstName: 'Alexandru',
    lastName: 'Tudorache',
    role: 'Backend Dev',
    image: tudorache,
  },
  {
    firstName: 'Andreea',
    lastName: 'Stefan',
    role: 'Backend Dev',
    image: astefan,
  },
  {
    firstName: 'Andrei',
    lastName: 'Rycov',
    role: 'Frontend Dev',
    image: andreir,
  },
  {
    firstName: 'Arkan',
    lastName: 'Gerges',
    role: 'Backend Dev',
    image: arkan,
  },
  {
    firstName: 'Bogdan',
    lastName: 'Burcea',
    role: 'Founder, Designer, UX/UI',
    image: bogdan,
  },
  {
    firstName: 'Bogdan',
    lastName: 'Gheorghe',
    role: 'Product Manager',
    image: gheorghe,
  },
  {
    firstName: 'Bogdan',
    lastName: 'Lacatusu',
    role: 'Backend Dev',
    image: blacatusu,
  },
  {
    firstName: 'Casian',
    lastName: 'Lacatusu',
    role: 'Backend Dev, Solidity Dev',
    image: casian,
  },
  {
    firstName: 'Catalin',
    lastName: 'Bara',
    role: 'Backend Dev',
    image: catalinbara,
  },
  {
    firstName: 'Catalin',
    lastName: 'Dorgo',
    role: 'QA',
    image: catalindorgo,
  },
  // {
  //   firstName: 'Catalin',
  //   lastName: 'Dragu',
  //   role: 'Designer UX/UI',
  //   image: catalin,
  // },
  {
    firstName: 'Cezar',
    lastName: 'Paraschiv',
    role: 'Founder, Product Manager',
    image: cezar,
  },
  {
    firstName: 'Cristian',
    lastName: 'Bajenaru',
    role: 'DevOps',
    image: cristi,
  },
  {
    firstName: 'Denis',
    lastName: 'Firu',
    role: 'Designer, UX/UI',
    image: denis,
  },
  {
    firstName: 'Dragos',
    lastName: 'Rizescu',
    role: 'Founder, Product Manager, Frontend Dev',
    image: dragos,
  },
  {
    firstName: 'George',
    lastName: 'Stefan',
    role: 'Backend Dev',
    image: gstefan,
  },
  {
    firstName: 'Liviu',
    lastName: 'Damian',
    role: 'DevOps',
    image: liviu,
  },
  {
    firstName: 'Milad',
    lastName: 'Mostavi',
    role: 'Founder, System Architect',
    image: milad,
  },
  {
    firstName: 'Rares',
    lastName: 'Tarabega',
    role: 'Frontend Dev',
    image: rares,
  },
  {
    firstName: 'Razvan',
    lastName: 'Pop',
    role: 'Backend Dev, Solidity Dev',
    image: popra,
  },
  {
    firstName: 'Slava',
    lastName: 'Matvienco',
    role: 'Frontend Dev',
    image: slava,
  },
  {
    firstName: 'Stefan',
    lastName: 'Oancea',
    role: 'Frontend Dev',
    image: oancius,
  },
  {
    firstName: 'Vadim',
    lastName: 'Lungu',
    role: 'DevOps',
    image: vadim,
  },
  {
    firstName: 'Virgiliu',
    lastName: 'Pacuretu',
    role: 'writer',
    image: pvirgiliu,
  },
  {
    firstName: 'Vlad',
    lastName: 'Suciu',
    role: 'QA',
    image: vladsuciu,
  },
]
