import React from 'react';
import styles from './Product.module.scss';
import { useParams } from "react-router";
import {productsDetails} from "../../assets/content/productsList";
import ProductDetails from "../../components/ProductDetails";



const Product = () => {
  const params = useParams();
  let product = null;

  const productParam = params.productName;

  switch (productParam) {
    case 'barnbridge':
      product = productsDetails.barnbridge;
      break;
    case 'universe':
      product = productsDetails.universe;
      break;
    case 'singulardtv':
      product = productsDetails.singularDtv;
      break;
    case 'gnosis':
      product = productsDetails.gnosis;
      break;
    case 'breaker':
      product = productsDetails.breaker;
      break;
    case 'tokit':
      product = productsDetails.tokit;
      break;
    case 'alethio':
      product = productsDetails.alethio;
      break;
    case 'metamask':
      product = productsDetails.metamask;
      break;
    case 'codefi':
      product = productsDetails.codefi;
      break;
    case 'filecoin':
      product = productsDetails.filecoin;
      break;
    case 'makerdao':
      product = productsDetails.makerDao;
      break;
    case 'allinfra':
      product = productsDetails.allinfra;
      break;
    case 'atpar':
      product = productsDetails.atpar;
      break;
    case 'kaiko':
      product = productsDetails.kaiko;
      break;
  }


  return (
    <div className={styles.root}>
      { product && <ProductDetails product={product} /> }
    </div>
  );
};

export default Product;
