import React from 'react';
import {Carousel} from "react-responsive-carousel";

import 'react-responsive-carousel/lib/styles/carousel.min.css';


const ProductCarousel = ({images, handleChange, selectedItem}) => {
  return (
    <div className="product-carousel-wrapper">
      <Carousel
        onChange={(index) => {handleChange(index)}}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        swipeScrollTolerance={50}
        centerMode={true}
        centerSlidePercentage={window.innerWidth < 769 ? 100 : 33.33}
        emulateTouch={true}
        // selectedItem={window.innerWidth < 480 ? 0 : 1}
        selectedItem={selectedItem}
      >
        {
          images.map((item, index) => (
            <div className="product-carousel-item" key={index}>
              <img src={item} alt="product_image" />
            </div>
          ))
        }
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
