import React, {useState} from 'react';
import styles from './Homepage.module.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SkillsList from "../../components/SkillsList";
import Article from "../../components/Article";
import cs from "classnames";
import Members from "../../components/Members";
import MobileHeader from "../../components/MobileHeader";
import { ReactComponent as DownArrow } from '../../assets/img/downArrowWhiteBg.svg';
import { ReactComponent as Plus } from '../../assets/img/plusCursorBlackBg.svg';
import { ReactComponent as MenuWhite } from '../../assets/img/menuWhiteBg.svg';
import { ReactComponent as XWhite } from '../../assets/img/xWhiteBg.svg';
import { ReactComponent as LogoWhite } from '../../assets/img/logo_white_bg.svg';
import MobileMembers from "../../components/MobileMembers";
import MobileNews from "../../components/MobileNews";
import { Controller, Scene } from 'react-scrollmagic';
import Drawer from "../../components/Drawer";
import ScrollableAnchor from 'react-scrollable-anchor'
import GlitchText from "../../components/GlitchText";
import TabletMembers from "../../components/TabletMembers";
import {productsList} from "../../assets/content/productsList";
import {NavLink} from "react-router-dom";
import {newsList} from "../../assets/content/newsList";

const values = ["blockchain architects . designers and builders . smart contract developers . writing automated machines . orchestrating servers . operating databases . perpetual development . flawless deployment . interface for timeless algorithms"];

const Homepage = () => {
  const [hoveredArticle, setHoveredArticle] = useState(false);
  const [isHoveredProduct, setIsHoveredProduct] = useState(null);
  const [hoveredProduct, setHoveredProduct] = useState(0);
  const [hoveredMember, setHoveredMember] = useState(false);
  const [scrollLeftProducts, setScrollLeftProducts] = useState(false);
  const [scrollRightProducts, setScrollRightProducts] = useState(false);
  const [cursorPosition, setCursorPosition] = useState([0, 0]);
  const [memberCursorPosition, setMemberCursorPosition] = useState([0, 0]);
  const [expandedFlowSection, setExpandedFlowSection] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prodViewMode, setProdViewMode] = useState(!localStorage.getItem('prodViewMode') || localStorage.getItem('prodViewMode') === 'default' ? 'default' : 'list');

  const scrollText = (e) => {
    if (e.pageX < window.innerWidth * 0.2) {
      setScrollRightProducts(false);
      setScrollLeftProducts(true);
    }
    if (e.pageX > window.innerWidth * 0.8) {
      setScrollLeftProducts(false);
      setScrollRightProducts(true);
    }
  }
  const clearScrollText = () => {
    setScrollRightProducts(false);
    setScrollLeftProducts(false);
  }

  const onProductHover = (e) => {
    setCursorPosition([e.pageX, e.pageY]);
  }

  const onMemberHover = (e) => {
    setMemberCursorPosition([e.pageX, e.pageY]);
  }

  const onProductEnter = index => {
    if (prodViewMode === 'list' && window.location.hash === '#work') {
      setIsHoveredProduct(true);
      setHoveredProduct(index);
    } else if (prodViewMode === 'default') {
      setIsHoveredProduct(true);
      setHoveredProduct(index);
    }
  }

  const onChangeProdViewMode = () => {
    if (!localStorage.getItem('prodViewMode') || localStorage.getItem('prodViewMode') === 'default') {
      localStorage.setItem('prodViewMode', 'list');
      setProdViewMode('list');
    } else {
      localStorage.setItem('prodViewMode', 'default');
      setProdViewMode('default');
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.menuBtn} onClick={() => setIsMenuOpen(true)}><MenuWhite /></div>
      <Drawer
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        position="right"
      >
        <div className="mobileMenu">
          <div className="mobileMenuHeader">
            <p>Digital M.O.B.</p>
            <div className="mobileMenuLogo"><LogoWhite /></div>
          </div>
          <div className="mobileMenuList">
            <a href='#home' className={`mobileMenuItem ${!window.location.hash.includes('#') || window.location.hash.includes('#home') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>home</a>
            <a href='#work' className={`mobileMenuItem ${window.location.hash.includes('#work') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>work</a>
            <a href='#flow' className={`mobileMenuItem ${window.location.hash.includes('#flow') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>flow</a>
            <a href='#team' className={`mobileMenuItem ${window.location.hash.includes('#team') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>team</a>
            <a href='#press' className={`mobileMenuItem ${window.location.hash.includes('#press') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>press</a>
            <a href='#contact' className={`mobileMenuItem ${window.location.hash.includes('#contact') ? 'selected' : 'null'}`} onClick={() => setIsMenuOpen(false)}>contact</a>
          </div>
          <div className="mobileLanguageSelector">EN</div>
          <button className="closeMobileMenu" type="button" onClick={() => setIsMenuOpen(false)}>
            <XWhite />
          </button>
        </div>
      </Drawer>

      <div className={styles.productDetails} style={{  opacity: isHoveredProduct ? '1' : '0', top: cursorPosition[1] - 198, left: cursorPosition[0] - 264, display: prodViewMode === 'default' ? 'block' : 'none'}}>
        <img src={productsList[hoveredProduct]?.thumbnail} alt="thumbnail"/>
      </div>
      <div className={styles.productDetailsListMode} style={{  opacity: isHoveredProduct ? '1' : '0', display: prodViewMode === 'default' ? 'none' : 'block'}}>
        <img src={productsList[hoveredProduct]?.thumbnail} alt="thumbnail"/>
      </div>
      <div className={styles.memberPhoto} style={{  opacity: hoveredMember ? '1' : '0',visibility: hoveredMember ? 'visible' : 'hidden' , top: memberCursorPosition[1] - 172, left: memberCursorPosition[0] - 172}}><img src={hoveredMember.image} alt='memberPhoto'/></div>
      <ScrollableAnchor id={'home'}>
        <div>
          <Header openMenu={() => setIsMenuOpen(true)}/>
          <MobileHeader />
          <div className={styles.homeSection}>
            <div className={styles.mobileOnlySectionHeader}>we.are</div>
            <div className={styles.text}>
              <GlitchText values={values} />
            </div>
          </div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id={'work'}>
        <div className={cs(styles.workSection, { [styles.left]: prodViewMode === 'list' })}>
          <div className={styles.tabletOnlySectionHeader}>we.made</div>
          <div id="trigger" />
          <div className={styles.wrapper} onMouseOver={(e) => scrollText(e)} onMouseLeave={() => clearScrollText()} style={{display: prodViewMode === 'default' || window.innerWidth <= 1024 ? 'block' : 'none'}}>
            <div className={cs(styles.text, { [styles.scrollLeft]: scrollLeftProducts }, { [styles.scrollRight]: scrollRightProducts } )}>
              <Controller>
                {productsList.map((product, index) => (
                  <Scene duration={() => document.getElementById(`${product.name}${index}`).offsetHeight} classToggle="activeProduct" indicators={false} key={index}>
                    <div
                      id={`${product.name}${index}`}
                      className={cs(styles.item, { [styles.gray]: isHoveredProduct })}
                      onMouseEnter={() => window.innerWidth > 1024 ? onProductEnter(index) : null}
                      onMouseLeave={() => window.innerWidth > 1024 ? setIsHoveredProduct(false) : null}
                      onMouseMove={(e) => onProductHover(e)}
                    >
                      <div className="productOverlay">
                        <img className="productThumbnail" src={productsList[index]?.thumbnail} alt="thumbnail"/>
                        <div className="productOverlayPlus">
                          <NavLink to={`/product/${product.urlParam}`}><Plus /></NavLink>
                        </div>
                      </div>
                      <p><NavLink to={`/product/${product.urlParam}`}>{product.name}</NavLink></p>
                    </div>
                  </Scene>
                ))}
              </Controller>
            </div>
          </div>
          <div className={styles.wrapper} style={{display: prodViewMode === 'list' && window.innerWidth > 1024 ? 'block' : 'none'}}>
            <div className={cs(styles.text, styles.listMode)}>
              {productsList.map((product, index) => (
                  <div
                    id={`${product.name}${index}`}
                    className={cs(styles.item, { [styles.gray]: isHoveredProduct })}
                    onMouseEnter={() => window.innerWidth > 1024 ? onProductEnter(index) : null}
                    onMouseLeave={() => window.innerWidth > 1024 ? setIsHoveredProduct(false) : null}
                    key={index}
                  >
                    <p><NavLink to={`/product/${product.urlParam}`}>{product.name}</NavLink></p>
                  </div>
              ))}
            </div>
          </div>
          <div className={styles.viewModeToggle} onClick={() => onChangeProdViewMode()}>{prodViewMode === 'default' ? 'view as list' : 'default view'}</div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id={'flow'}>
        <div className={styles.flowSection}>
          <div className={styles.wrapper}>
            <p className={styles.title}>processes.precision.flow</p>
            <p className={styles.description}>We start from idea validation and carry the project to deployment. Frequent updates and a constant stream of communication with our clients is the most efficient way to get projects up and running perfectly. Fast pacing and around the clock technology requires transparent, swift and precise execution. Experienced teams deliver thoroughly audited and tested software products.</p>
            <p className={cs(styles.use, styles.mobileHidden)}>we.use</p>
            <SkillsList mobileHidden/>
            <p className={cs(styles.use, styles.mobileHidden)}>we.deliver</p>
            <p className={cs(styles.deliverBoldText, styles.mobileHidden)}>Blockchain does not work isolated. We build complete systems. Platforms. Interfaces. Websites. Apps. In the past decade we delivered enterprise grade, blockchain centered solutions for startups and fortune 500 companies.</p>
            <p className={cs(styles.deliverText, styles.mobileHidden)}>Develop solutions for the blockchain. Grow. Build precise software. Create smart contracts.  Translate EVM opcodes. Develop tokens and token use cases. Validate ideas. Manage scalable architecture. Research and development. Manage projects. Learn & teach. Peer-programming.  Code-review. Quality assurance. Test. Audit. Product design. UI / UX. Write [beautiful] code.</p>
            { !expandedFlowSection && (
              <div className={cs(styles.expandRow)} onClick={() => setExpandedFlowSection(true)}>
                <DownArrow />
                <div className={styles.text}>read.more</div>
              </div>
            )}
            { expandedFlowSection && (
              <div>
                <p className={cs(styles.use)}>we.use</p>
                <SkillsList />
                <p className={cs(styles.use)}>we.deliver</p>
                <p className={cs(styles.deliverBoldText)}>Blockchain does not work isolated. We build complete systems. Platforms. Interfaces. Websites. Apps. In the past decade we delivered enterprise grade, blockchain centered solutions for startups and fortune 500 companies.</p>
                <p className={cs(styles.deliverText)}>Develop solutions for the blockchain. Grow. Build precise software. Create smart contracts.  Translate EVM opcodes. Develop tokens and token use cases. Validate ideas. Manage scalable architecture. Research and development. Manage projects. Learn & teach. Peer-programming.  Code-review. Quality assurance. Test. Audit. Product design. UI / UX. Write [beautiful] code.</p>
                {/*<div className={styles.collapseRow} onClick={() => setExpandedFlowSection(false)}><Collapse /></div>*/}
              </div>
            )}
          </div>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id={'team'}>
        <div className={styles.teamSection}>
          <div className={styles.title}>we.the.m.o.b.</div>
          <div className={styles.description}>web3 developers . web and mobile developers . system architects . security experts . analysts . project managers . designers</div>
          <Members
            setHoveredMember={setHoveredMember}
            hoveredMember={hoveredMember}
            onMemberHover={(e) => onMemberHover(e)}
          />
          <TabletMembers />
          <MobileMembers />
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id={'press'}>
        <div className={styles.newsSection}>
          <div className={styles.wrapper}>
            <p className={styles.title}>m.o.b.sters.in.the.news</p>
            <MobileNews />
            <div className={styles.articles}>
              {newsList.map((item, index) => (
                <Article
                  date={item.date}
                  text={item.title}
                  externalUrl={item.url}
                  onEnter={() => setHoveredArticle(true)}
                  onLeave={() => setHoveredArticle(false)}
                  hoveredArticle={hoveredArticle}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </ScrollableAnchor>
      <Footer />
    </div>
  );
};

export default Homepage;
