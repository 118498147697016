import React, {useState} from 'react';
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {membersList} from "../../assets/content/membersList";

const MobileMembers = () => {

  const [currentMember, setCurrentMember] = useState({ member: membersList[0], index: 0})

  const onChangeHandler = (index, item) => {
    setCurrentMember({ member: membersList[index], index: index })
  }

  return (
    <div className="members-carousel-wrapper">
      <Carousel
        onChange={(index, item) => onChangeHandler(index, item)}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        centerMode={true}
        centerSlidePercentage={80}
        swipeScrollTolerance={30}
      >
        {membersList.map((item, index) => (
          <div className="memberWrapper" key={index}>
            <img src={item.image} alt="_avatar"/>
          </div>
        ))}
      </Carousel>
      <div className="memberFirstName">{currentMember.member.firstName}</div>
      <div className="memberLastName">{currentMember.member.lastName}</div>
      <div className="memberRole">{currentMember.member.role}</div>
      <div className="memberProgressBarWrapper"><div className="memberProgressBarRect" style={{left: (currentMember.index / membersList.length) * 100 + '%', width: 100 / membersList.length + '%'}}/></div>
    </div>
  );
};

export default MobileMembers;
